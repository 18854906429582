<div class="page-title-area title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>Our Blogs</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Blogs</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="blog-area blog-area-two  pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="blog-item wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                    <a routerLink="/blog-details">
                        <img src="assets/img/home-one/blog/1.jpg" alt="Blog">
                    </a>

                    <div class="blog-inner">
                        <span>Rights Case</span>
                        <h3><a routerLink="/blog-details">Justice May For You If You Are Innocent</a></h3>
                        <ul>
                            <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                            <li><i class="icofont-user-alt-7"></i> <a routerLink="/blog">Aman Khurana</a></li>
                        </ul>
                        <p>Being wrongfully accused or denied basic rights can be devastating. Understanding the legal avenues available can make all the difference. In this article, Aman Khurana explores the importance of speaking out and how our justice system can help those who find themselves wrongly accused or disenfranchised.</p>
                        <a class="blog-link" routerLink="/blog-details">Read More<i class="icofont-simple-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                    <a routerLink="/blog-details">
                        <img src="assets/img/home-one/blog/2.jpg" alt="Blog">
                    </a>

                    <div class="blog-inner">
                        <span>Business Case</span>
                        <h3>
                            <a routerLink="/blog-details">By Whom Your Business Is Being Loss?</a>
                        </h3>
                        <ul>
                            <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                            <li><i class="icofont-user-alt-7"></i> <a routerLink="/blog">Nishant Bhatia</a></li>
                        </ul>
                        <p>Businesses face several legal challenges that can impact their growth and stability. From contractual disputes to regulatory hurdles, understanding these risks and knowing how to navigate them is crucial. Nishant Bhatia sheds light on the common legal pitfalls affecting businesses and how proactive legal measures can safeguard your success.</p>
                        <a class="blog-link" routerLink="/blog-details">Read More<i class="icofont-simple-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                    <a routerLink="/blog-details">
                        <img src="assets/img/home-one/blog/3.jpg" alt="Blog">
                    </a>

                    <div class="blog-inner">
                        <span>Criminal Case</span>
                        <h3><a routerLink="/blog-details">Who Can The Victim A Sue After A Car Accident?</a></h3>
                        <ul>
                            <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                            <li><i class="icofont-user-alt-7"></i> <a routerLink="/blog">Rahul Mehra</a></li>
                        </ul>
                        <p>Car accidents can lead to life-changing consequences for victims. Who can be held liable, and what compensation options are available? Rahul Mehra discusses the steps to take after a car accident and the legal rights of victims seeking justice and fair compensation.</p>
                        <a class="blog-link" routerLink="/blog-details">Read More<i class="icofont-simple-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                    <a routerLink="/blog-details">
                        <img src="assets/img/home-one/blog/4.jpg" alt="Blog">
                    </a>

                    <div class="blog-inner">
                        <span>People Case</span>
                        <h3><a routerLink="/blog-details">Why Are People Doing Crime Day By Day?</a></h3>
                        <ul>
                            <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                            <li><i class="icofont-user-alt-7"></i> <a routerLink="/blog">Kunal Kapoor</a></li>
                        </ul>
                        <p>Crime rates are steadily increasing, leaving many to question the root causes behind this trend. Kunal Kapoor delves into societal factors that contribute to criminal behavior and explores ways to combat these issues at both community and policy levels.</p>
                        <a class="blog-link" routerLink="/blog-details">Read More<i class="icofont-simple-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                    <a routerLink="/blog-details">
                        <img src="assets/img/home-one/blog/5.jpg" alt="Blog">
                    </a>

                    <div class="blog-inner">
                        <span>Society Case</span>
                        <h3><a routerLink="/blog-details">Why Is Crime Increasing In Our Society Now?</a></h3>
                        <ul>
                            <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                            <li><i class="icofont-user-alt-7"></i> <a routerLink="/blog">Vikram Saxena</a></li>
                        </ul>
                        <p>An increase in crime affects more than just victims; it impacts society as a whole. Vikram Saxena discusses the social consequences of rising crime rates and the importance of a collaborative effort between citizens and the justice system to address this growing concern.</p>
                        <a class="blog-link" routerLink="/blog-details">Read More<i class="icofont-simple-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                    <a routerLink="/blog-details">
                        <img src="assets/img/home-one/blog/6.jpg" alt="Blog">
                    </a>

                    <div class="blog-inner">
                        <span>Government Case</span>
                        <h3><a routerLink="/blog-details">Will The Government Prevent These Crimes?</a></h3>
                        <ul>
                            <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                            <li><i class="icofont-user-alt-7"></i> <a routerLink="/blog">Rishi Malhotra</a></li>
                        </ul>
                        <p>The government has implemented various measures to curb crime, but are they effective? Rishi Malhotra examines current policies and initiatives, highlighting their impact on crime reduction and the need for further reforms to achieve tangible results.</p>
                        <a class="blog-link" routerLink="/blog-details">Read More<i class="icofont-simple-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                    <a routerLink="/blog-details">
                        <img src="assets/img/home-one/blog/7.jpg" alt="Blog">
                    </a>

                    <div class="blog-inner">
                        <span>Corrupted Case</span>
                        <h3><a routerLink="/blog-details">The Society Is Being Corrupted Because Of Crime</a></h3>
                        <ul>
                            <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                            <li><i class="icofont-user-alt-7"></i> <a routerLink="/blog">Aditya Deshmukh</a></li>
                        </ul>
                        <p>Corruption is a breeding ground for crime, and as corruption flourishes, so does criminal behavior. Aditya Deshmukh explores the correlation between corruption and crime and discusses ways to break this cycle to build a more transparent and lawful society.</p>
                        <a class="blog-link" routerLink="/blog-details">Read More<i class="icofont-simple-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                    <a routerLink="/blog-details">
                        <img src="assets/img/home-one/blog/8.jpg" alt="Blog">
                    </a>

                    <div class="blog-inner">
                        <span>Justice Case</span>
                        <h3><a routerLink="/blog-details">You Will Get Justice If You Are Innocent</a></h3>
                        <ul>
                            <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                            <li><i class="icofont-user-alt-7"></i> <a routerLink="/blog">Varun Sharma</a></li>
                        </ul>
                        <p>Ensuring justice for the innocent is the cornerstone of our legal system. Varun Sharma offers insights into the legal protections in place for the wrongly accused and how victims of false accusations can find hope and support within the legal framework.</p>
                        <a class="blog-link" routerLink="/blog-details">Read More<i class="icofont-simple-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="blog-item wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                    <a routerLink="/blog-details">
                        <img src="assets/img/home-one/blog/9.jpg" alt="Blog">
                    </a>

                    <div class="blog-inner">
                        <span>Crime Case</span>
                        <h3><a routerLink="/blog-details">Stay Far From Crime Aware About Law</a></h3>
                        <ul>
                            <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                            <li><i class="icofont-user-alt-7"></i> <a routerLink="/blog">Arjun Chawla</a></li>
                        </ul>
                        <p>Staying informed about the law can help individuals protect themselves from becoming victims or inadvertently breaking the law. Arjun Chawla discusses key legal concepts and practical advice to stay law-aware and vigilant in today’s ever-changing landscape.</p>
                        <a class="blog-link" routerLink="/blog-details">Read More<i class="icofont-simple-right"></i></a>
                    </div>
                </div>
            </div>
        </div>

        <div class="case-pagination">
            <ul>
                <li><a routerLink="/blog"><i class="icofont-simple-left"></i></a></li>
                <li><a routerLink="/blog">1</a></li>
                <li><a routerLink="/blog">2</a></li>
                <li><a routerLink="/blog">3</a></li>
                <li><a routerLink="/blog"><i class="icofont-simple-right"></i></a></li>
            </ul>
        </div>
    </div>
</section>