<div class="banner-area banner-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-item">
                            <div class="banner-left">
                                <h1>Defending Your Rights, Delivering Justice</h1>
                                <h3>Experienced Attorneys Dedicated to Your Legal Success</h3>
                                <p>At The lawyerss, we are committed to delivering excellence in legal representation. Our law firm specializes in providing strategic, client-focused counsel in diverse legal areas. From complex litigation to sensitive family matters, our team stands ready to advocate for your rights with integrity and professionalism.</p>
                                <a routerLink="/">Schedule Your Free Consultation | Government-Certified Experts <i class="icofont-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="banner-item">
                            <div class="banner-right">
                                <img class="banner-animation" src="assets/img/home-one/3.png" alt="Banner">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="banner-bottom">
            <ul>
                <li><i class="flaticon-leader"></i></li>
                <li>
                    <span>100% Legal</span>
                    <p>Govt Approved</p>
                    <img src="assets/img/home-one/9.png" alt="Shape">
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="help-area pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="help-item help-left">
                    <img src="assets/img/home-one/5.jpg" alt="Help">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="help-item">
                    <div class="help-right">
                        <h2>Your Trusted Legal Advisors</h2>
                        <h3>Why Choose The Lawyers?</h3>
                        <p>The legal team at The lawyerss brings unparalleled experience, a results-driven approach, and an unwavering commitment to achieving the best outcomes for our clients. We offer a comprehensive range of legal services and personalized attention to every case.</p>
                        <div class="help-inner-left">
                            <ul>
                                <li><i class="flaticon-checkmark"></i> Explore Our Expertise</li>
                                <li><i class="flaticon-checkmark"></i> Choose Your Legal Service</li>
                            </ul>
                        </div>

                        <div class="help-inner-right">
                            <ul>
                                <li><i class="flaticon-checkmark"></i> Responsive Legal Guidance</li>
                                <li><i class="flaticon-checkmark"></i> Proven Track Record</li>
                            </ul>
                        </div>

                        <div class="help-signature">
                            <img src="assets/img/home-one/4.png" alt="Signature">
                        </div>

                        <a class="cmn-btn" routerLink="/practice">Learn More</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="help-shape">
            <img src="assets/img/home-one/6.png" alt="Shape">
        </div>
    </div>
</div>

<section class="practice-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>HOW CAN WE HELP YOU</span>
            <h2>Our Areas of Practice</h2>
            <h4>Providing Expert Legal Representation Across Multiple Sectors</h4>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-law"></i>
                    </div>
                    <h3>Civil Law</h3>
                    <p>Our highly experienced civil law attorneys excel at resolving disputes efficiently while protecting your rights and interests. We offer top-tier legal counsel in civil litigation, contract disputes, and property matters.</p>
                    <a routerLink="/practice-details">Read More</a>
                    <img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
                    <img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-family"></i>
                    </div>
                    <h3>Family Law</h3>
                    <p>With compassion and legal acumen, our family law attorneys skillfully handle cases involving divorce, child custody, alimony, and more. We are dedicated to achieving fair outcomes and providing strong representation for families.</p>
                    <a routerLink="/practice-details">Read More</a>
                    <img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
                    <img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-inheritance"></i>
                    </div>
                    <h3>Business Law</h3>
                    <p>Our corporate legal experts provide comprehensive legal solutions to safeguard and grow your business. From contract drafting to compliance and mergers, our attorneys offer sound legal strategies.</p>
                    <a routerLink="/practice-details">Read More</a>
                    <img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
                    <img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-mortarboard"></i>
                    </div>
                    <h3>Education Law</h3>
                    <p>Navigating the complexities of education law requires expertise and insight. Our attorneys advocate for educational institutions, staff, and students, offering legal support for regulatory compliance, disputes, and policy matters.</p>
                    <a routerLink="/practice-details">Read More</a>
                    <img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
                    <img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-auction"></i>
                    </div>
                    <h3>Criminal Law</h3>
                    <p>Facing criminal charges can be overwhelming. Our seasoned criminal defense attorneys employ a strategic and aggressive approach to protect your rights and achieve the best possible outcome for your case.</p>
                    <a routerLink="/practice-details">Read More</a>
                    <img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
                    <img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-vulnerability"></i>
                    </div>
                    <h3>Cyber Law</h3>
                    <p>In an increasingly digital world, our cyber law specialists are equipped to handle cases related to data breaches, cybercrimes, and digital privacy. We offer expert legal guidance and defense in cyber law matters.</p>
                    <a routerLink="/practice-details">Read More</a>
                    <img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
                    <img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="expertise-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="section-title text-start">
                    <span>OUR EXPERTISE</span>
                    <h2>Why Clients Trust Us</h2>
                </div>

               <div class="expertise-item">
                    <ul>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <div class="expertise-icon">
                                <i class="flaticon-experience"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Unmatched Experience</h3>
                            <p>Our team of seasoned legal professionals brings decades of collective experience across various practice areas, delivering strategic and informed counsel to every client.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                            <div class="expertise-icon">
                                <i class="flaticon-lawyer"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Innovative Legal Solutions</h3>
                            <p>We pride ourselves on our attorneys’ creativity and problem-solving skills, which allow us to develop innovative strategies to navigate complex legal situations.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <div class="expertise-icon">
                                <i class="flaticon-balance"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Adherence to Legal Integrity</h3>
                            <p>We maintain meticulous documentation and uphold the highest standards of legal integrity, ensuring that every action aligns with the law and our clients' best interests.</p>
                        </li>
                    </ul>
               </div>
            </div>

            <div class="col-lg-6">
                <div class="expertise-image">
                    <img src="assets/img/home-one/10.png" alt="Expertise">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="portfolio-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>PORTFOLIO</span>
            <h2>Check Out Our Popular Case Studies.</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="portfolio-item">
                    <img src="assets/img/home-one/portfolio/1.jpg" alt="Portfolio">

                    <div class="portfolio-inner">
                        <span>Crime</span>
                        <h3><a routerLink="/case-study-details">Critical Murder Case</a></h3>
                        <p>15 Feb 2023</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="portfolio-item">
                    <img src="assets/img/home-one/portfolio/2.jpg" alt="Portfolio">

                    <div class="portfolio-inner">
                        <span>Rights</span>
                        <h3><a routerLink="/case-study-details">Children Rights</a></h3>
                        <p>16 Feb 2023</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="portfolio-item">
                    <img src="assets/img/home-one/portfolio/3.jpg" alt="Portfolio">

                    <div class="portfolio-inner">
                        <span>Finance</span>
                        <h3><a routerLink="/case-study-details">Money Loundering</a></h3>
                        <p>17 Feb 2023</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-center">
            <a class="cmn-btn" routerLink="/case-study">See More</a>
        </div>
    </div>
</section>

<section class="team-area">
    <!-- <div class="container">
        <div class="section-title">
            <span>TEAM MEMBER</span>
            <h2>Meet Our Expert Attorneys</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <img src="assets/img/home-one/team/1.jpg" alt="Team">
                    
                    <div class="team-inner">
                        <ul>
                            <li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                        <h3><a routerLink="/attorney-details">Attor. Rohan Sethi</a></h3>
                        <span>Family Consultant</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <img src="assets/img/home-one/team/2.jpg" alt="Team">
                    
                    <div class="team-inner">
                        <ul>
                            <li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                        <h3><a routerLink="/attorney-details">Attor. Anjali Menon</a></h3>
                        <span>Criminal Consultant</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <img src="assets/img/home-one/team/3.jpg" alt="Team">
                    
                    <div class="team-inner">
                        <ul>
                            <li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                        <h3><a routerLink="/attorney-details">Attor. Vikram Desai</a></h3>
                        <span>Divorce Consultant</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <img src="assets/img/home-one/team/4.jpg" alt="Team">

                    <div class="team-inner">
                        <ul>
                            <li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                        <h3><a routerLink="/attorney-details">Attor. Kavita Nair</a></h3>
                        <span>Business Consultant</span>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</section>

<div class="contact-form pb-100">
    <div class="loaction-area">
        <div class="container">
            <div class="row location-bg">
                <div class="col-sm-6 col-lg-4">
                    <div class="location-item">
                        <div class="location-icon">
                            <i class="flaticon-pin"></i>
                            <img src="assets/img/home-one/12.png" alt="Shape">
                        </div>
                        <h3>Location</h3>
                        <ul>
                            <li>High Court Building, Dr. Kane Road, Fort, Mumbai, Maharashtra 400032, India</li>
                        </ul>
                        <a class="location-link" href="#" target="_blank">View on gogle map</a>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-4">
                    <div class="location-item">
                        <div class="location-icon">
                            <i class="flaticon-email"></i>
                            <img src="assets/img/home-one/12.png" alt="Shape">
                        </div>
                        <h3>Email</h3>
                        <ul>
                            <li><a href="mailto:contact@thelawyerss.com">contact@thelawyerss.com</a></li>
                            <li><a href="mailto:support@thelawyerss.com">support@thelawyerss.com</a></li>
                        </ul>
                        <!-- <a class="location-link" href="#" target="_blank">View on gogle map</a> -->
                    </div>
                </div>

                <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                    <div class="location-item">
                        <div class="location-icon">
                            <i class="flaticon-call"></i>
                            <img src="assets/img/home-one/12.png" alt="Shape">
                        </div>
                        <h3>Phone</h3>
                        <ul>
                            <li><a href="tel:+919823456789">+91 9823456789</a></li>
                            <li><a href="tel:+919087654321">+91 9087654321</a></li>
                        </ul>
                        <!-- <a class="location-link" href="#" target="_blank">View on gogle map</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <form id="contactForm">
            <div class="row contact-wrap">
                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="text" name="name" id="name" class="form-control" placeholder="Your Full Name">
                    </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="email" name="email" id="email" class="form-control" placeholder="Your Email">
                    </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Your Phone">
                    </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Subject">
                    </div>
                </div>

                <div class="col-md-12 col-lg-12">
                    <div class="form-group">
                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" placeholder="Case Description"></textarea>
                    </div>
                </div>

                <div class="col-md-12 col-lg-12">
                    <div class="text-center">
                        <button type="submit" class="contact-btn">Submit Case</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<section class="blog-area pt-100">
    <div class="container">
        <div class="section-title">
            <span>BLOG</span>
            <h2>Our Latest Blogs</h2>
        </div>

        <div class="blog-slider owl-theme owl-carousel">
            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/1.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Rights Case</span>
                    <h3><a routerLink="/blog-details">Justice May For You If You Are Innocent</a></h3>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">Manish Joshi</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>

            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/2.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Business Case</span>
                    <h3><a routerLink="/blog-details">By Whom Your Business Is Being Loss?</a></h3>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">Neeta Shah</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>

            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/3.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Criminal Case</span>
                    <h3><a routerLink="/blog-details">Who Can The Victim A Sue After A Car Accident?</a></h3>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">Priya Iyer</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>

            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/1.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Rights Case</span>
                    <h3><a routerLink="/blog-details">Justice May For You If You Are Innocent</a></h3>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">Devendra Patel</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>

            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/2.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Business Case</span>
                    <h3><a routerLink="/blog-details">By Whom Your Business Is Being Loss?</a></h3>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">Sunita Bhattacharya</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>

            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/3.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Criminal Case</span>
                    <h3><a routerLink="/blog-details">Who Can The Victim A Sue After A Car Accident?</a></h3>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">Arjun Kapoor</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>

            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/1.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Rights Case</span>
                    <h3><a routerLink="/blog-details">Justice May For You If You Are Innocent</a></h3>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">Kavita Nair</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>

            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/2.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Business Case</span>
                    <h3>
                        <a routerLink="/blog-details">By Whom Your Business Is Being Loss?</a></h3><ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">Aman Khurana</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>

            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/3.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Criminal Case</span>
                    <h3><a routerLink="/blog-details">Who Can The Victim A Sue After A Car Accident?</a></h3>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">Nishant Bhatia</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>