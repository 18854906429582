import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoaderService } from 'src/app/service/loader.service';
import { MasterService } from 'src/app/service/master.service';

@Component({
  selector: 'app-customer-question',
  templateUrl: './customer-question.component.html',
  styleUrls: ['./customer-question.component.scss']
})
export class CustomerQuestionComponent implements OnInit {
  customerAskedQuestions: any = [];
  selectedQuestion: any = null;
  answerText: string = '';
  constructor(private fb: FormBuilder, private loaderService: LoaderService, private service: MasterService) { }

  ngOnInit(): void {

    this.getAutoFillData();
  }

  getAutoFillData() {
    this.loaderService.show();
    const userMasterId = parseInt(localStorage.getItem("userMasterId"));

    const recentQuestionsAPI$ = this.service.get(`lawyer/query/${userMasterId}?pageNo=1&pageSize=10`).pipe(
      catchError((err) => {
        console.log('Failed to get top 5 Customer asked questions.', err);
        return of({ isSuccess: false, data: [] });
      })
    )


    forkJoin({ recentQuestionsAPI: recentQuestionsAPI$ }).subscribe({
      next: (results: any) => {
        if (results.recentQuestionsAPI.isSuccess) {
          this.customerAskedQuestions = results.recentQuestionsAPI.data;
        } else {
          console.log("No questions found.");
        }
      },
      complete: () => {
        this.loaderService.hide();
      }
    })
  }
  answerQuestion(question: any) {
    this.selectedQuestion = question;
    console.log(this.selectedQuestion);
    
  }
  cancelAnswer(){
    this.selectedQuestion = null;
  }
  submitAnswer() {
    if (this.answerText) {
      // Call your service to submit the answer
      this.loaderService.show();
      const payload = {
        "custQuestionId": this.selectedQuestion.custQuestionId,
        "answer": this.answerText
      }
      this.service.post(`lawyer/query/answer`, payload).subscribe({
        next: (res: any) => {
          alert('Answer Submitted.');
        }, error: (err: any) => {
          alert('Error');
          this.loaderService.hide();
        }, complete: () => {
          this.loaderService.hide();
        }
      })
    }
  }
}
