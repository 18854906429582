<div class="page-title-area title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>Free Legal Advice</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Ask Free Legal Query</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="container" style="margin-top: 1rem; margin-bottom: 1rem;">
    <div class="row">
        <div class="col-md-6">
            <div style="box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;">
                <div class="query-header" style="border-bottom: 1px solid #e4e4e4; padding: 16px 24px;">
                    <div class="d-flex align-items-center gap-3">
                        <i class="ic--round-business"></i>
                        <span>Customer Legal Queries</span>
                    </div>
                </div>
                <div class="queries" style="border-bottom: 1px solid #e4e4e4; padding: 16px 24px;"
                    *ngFor="let customerQuestion of customerAskedQuestions">
                    <h5 style="font-weight: 400;">{{customerQuestion.title}}</h5>
                    <span
                        style="background: #e0f5d7; color: #449626; font-size: .9rem; padding: 4px 8px;">{{customerQuestion.areaOfLaw}}</span>
                    <p style="font-size: 0.9rem; margin-top: 5px;">{{customerQuestion.question}}</p>
                    <div class="d-flex justify-content-end">
                        <button (click)="answerQuestion(customerQuestion)" class="btn lufz-btn" style="margin-top: 10px;">Answer</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Right side: Answer form -->
        <div class="col-md-6" *ngIf="selectedQuestion">
            <div style="box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;">
                <div class="query-header" style="border-bottom: 1px solid #e4e4e4; padding: 16px 24px;">
                    <div class="d-flex align-items-center justify-content-between gap-3">
                        <div class="d-flex align-items-center gap-3">
                            <i class="fluent-mdl2--book-answers"></i>
                            <span>Answer the Query</span>
                        </div>
                        <i class="carbon--close-outline" style="cursor: pointer;" (click)="cancelAnswer();"></i>
                    </div>
                </div>
                <div style="padding: 16px 24px;">
                    <div class="mb-3">
                        <label for="questionTitle" class="form-label">Question Title</label>
                        <input type="text" id="questionTitle" class="form-control" [value]="selectedQuestion?.title"
                            readonly />
                    </div>
                    <div class="mb-3">
                        <label for="questionDescription" class="form-label">Question Description</label>
                        <textarea rows="4" id="questionDescription" class="form-control" [value]="selectedQuestion?.question"
                            readonly></textarea>
                    </div>
                    <div class="mb-3">
                        <label for="answer" class="form-label">Your Answer</label>
                        <textarea id="answer" class="form-control" [(ngModel)]="answerText" rows="4"></textarea>
                    </div>
                    <div class="d-flex justify-content-end">
                        <button type="submit" class="btn lufz-btn" (click)="submitAnswer()">Submit Answer</button>
                    </div>
                </div>
            </div>

        </div>
    </div>