<div class="page-title-area title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>Terms & Conditions</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Terms & Conditions</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="privacy-area pt-100">
    <div class="container">
        <div class="privacy-item">
            <h2>1. Understanding Our Terms & Conditions</h2>
            <p>Our <strong>Terms & Conditions</strong> outline the rules and guidelines for using <strong>The Lawyerss</strong> website, services, and content. By accessing our website, you agree to comply with these terms, which are designed to protect both our users and our organization. These conditions help ensure fair usage and establish clear expectations regarding your interactions with our platform and legal services.</p>
        </div>

        <div class="privacy-item">
            <h2>2. How We Enforce Our Terms & Conditions</h2>
            <p>Our <strong>Terms & Conditions</strong> are enforced to maintain the integrity of our services and protect the interests of all parties involved. We adhere to these terms to ensure a professional and secure experience for our clients. We may modify or update these terms to reflect changes in legal standards or our practices. Continued use of our website and services after any updates indicates your acceptance of these changes.</p>
            <p>These terms apply to all visitors, clients, and users of our website and digital platforms. Violations of these terms may result in restricted access to services, termination of accounts, or other legal actions as deemed necessary to maintain a safe and trustworthy environment.</p>
        </div>

        <div class="privacy-item">
            <h2>3. Personal Data and Privacy</h2>
            
            <ul>
                <li><i class="icofont-simple-right"></i> Personal Identification Information: Such as your name, email address, and phone number provided during consultations or when subscribing to our communications.</li>
                <li><i class="icofont-simple-right"></i> Communication Data: Information related to your engagement with our website, newsletters, and legal updates.</li>
                <li><i class="icofont-simple-right"></i> Technical Data: Including your IP address and browsing behavior on our website to help us enhance user experience and security.</li>
                <li><i class="icofont-simple-right"></i> Interaction Data: Tracking whether you open newsletters or emails and what content you engage with, to improve our communications and offerings.</li>
            </ul>
            <p>We respect your privacy and are committed to protecting your personal information in accordance with our <a routerLink="/privacy-policy">Privacy Policy</a>. All data collected is used strictly for the purposes outlined in our policies, and we adhere to relevant legal frameworks to ensure your privacy is maintained.</p>
        </div>
    </div>
</section>
