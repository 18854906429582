<div class="page-title-area page-title-area-two title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>Register</h2>
            </div>
        </div>
    </div>
</div>
<div class="contact-form contact-form-four pb-100" style="background-color: #333333;">
    <div class="loaction-area">
        <!-- Form container with reactive form -->
        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
            <div class="row contact-wrap">

                <!-- First Name input -->
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" formControlName="firstName" id="firstName" class="form-control"
                            placeholder="First Name*" required
                            [ngClass]="{'border-danger': registerForm.get('firstName').touched && registerForm.get('firstName').invalid}" />
                        <div *ngIf="registerForm.get('firstName')?.touched && registerForm.get('firstName')?.invalid"
                            style="text-align: start;">
                            <small class="text-danger" style="font-size: 12px;">First Name is required.</small>
                        </div>
                    </div>
                </div>

                <!-- Last Name input -->
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" formControlName="lastName" id="lastName" class="form-control"
                            placeholder="Last Name" required />
                        <!-- <div *ngIf="registerForm.get('lastName')?.touched && registerForm.get('lastName')?.invalid" style="text-align: start;" [ngClass]="{'border-danger': registerForm.get('lastName').touched && registerForm.get('lastName').invalid}">
    <small class="text-danger" style="font-size: 12px;">Last Name is required.</small>
  </div> -->
                    </div>
                </div>

                <!-- Mobile Number input -->
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" formControlName="mobileNo" id="mobileNo" class="form-control"
                            placeholder="Mobile Number*" required />
                        <div *ngIf="registerForm.get('mobileNo')?.touched && registerForm.get('mobileNo')?.invalid">
                            <div *ngIf="registerForm.get('mobileNo')?.hasError('required')" style="text-align: start;">
                                <small class="text-danger" style="font-size: 12px;">Mobile number is
                                    required.</small>
                            </div>
                            <div *ngIf="registerForm.get('mobileNo')?.hasError('pattern')" style="text-align: start;">
                                <small class="text-danger" style="font-size: 12px;">Please enter a valid
                                    10-digit mobile number.</small>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Email input -->
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="email" formControlName="email" id="email" class="form-control" placeholder="Email*"
                            required />
                        <div *ngIf="registerForm.get('email')?.touched && registerForm.get('email')?.invalid">
                            <div *ngIf="registerForm.get('email')?.hasError('required')" style="text-align: start;">
                                <small class="text-danger" style="text-align: start; font-size: 12px;">Email is
                                    required.</small>
                            </div>
                            <div *ngIf="registerForm.get('email')?.hasError('email')" style="text-align: start;">
                                <small class="text-danger" style="text-align: start; font-size: 12px;">Please
                                    enter a valid email address.</small>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Password input with toggle visibility -->
                <div class="col-md-6">
                    <div class="form-group position-relative">
                        <input [type]="passwordFieldType" formControlName="password" id="password" class="form-control"
                            placeholder="Password*"
                            [ngClass]="{'border-danger': registerForm.get('password').touched && registerForm.get('password').invalid}" />
                        <i class="flaticon-eye position-absolute"
                            [ngClass]="{ 'flaticon-eye-open': !hidePassword, 'flaticon-eye-close': hidePassword }"
                            style="cursor: pointer; right: 25px; top: 50%; transform: translateY(-50%);"
                            (click)="togglePasswordVisibility()"></i>
                    </div>
                    <div *ngIf="registerForm.get('password')?.touched && registerForm.get('password')?.invalid"
                        class="error-messages">
                        <small *ngIf="registerForm.get('password')?.hasError('required')" class="text-danger">
                            Password is required.
                        </small>
                        <small *ngIf="registerForm.get('password')?.hasError('minlength')" class="text-danger">
                            Password must be at least 8 characters long.
                        </small>
                    </div>
                </div>

                <!-- Confirm Password input with toggle visibility -->
                <div class="col-md-6">
                    <div class="form-group position-relative">
                        <input [type]="confirmPasswordFieldType" formControlName="confirmPassword" id="confirmPassword"
                            class="form-control" placeholder="Confirm Password*"
                            [ngClass]="{'border-danger': registerForm.get('confirmPassword').touched && registerForm.hasError('passwordsMismatch')}" />
                        <i class="flaticon-eye position-absolute"
                            [ngClass]="{ 'flaticon-eye-open': !hideConfirmPassword, 'flaticon-eye-close': hideConfirmPassword }"
                            style="cursor: pointer; right: 25px; top: 50%; transform: translateY(-50%);"
                            (click)="toggleConfirmPasswordVisibility()"></i>
                    </div>
                    <div *ngIf="registerForm.get('confirmPassword')?.touched && registerForm.hasError('passwordsMismatch')"
                        class="error-messages">
                        <small class="text-danger">Passwords do not match.</small>
                    </div>
                </div>



            </div>
            <div class="invalid-form" style="display: flex; justify-content: center;">
                <small *ngIf="invalidForm" style="color: red; margin-bottom: 1rem;">
                    {{invalidFormError}}
                </small>
            </div>
            <div class="row contact-wrap">
                <!-- Custom switch button: Are you a Lawyer? -->
                <div class="col-md-6">
                    <!-- <div class="form-group d-flex align-items-center" style="display: none !important;">
<label for="customSwitch" class="mr-3">Are you a Lawyer?</label>
<label class="custom-switch">
<input type="checkbox" id="customSwitch" formControlName="isLawyer" />
<span class="custom-slider"></span>
</label>
</div> -->
                </div>

                <!-- Register button -->
                <div class="col-md-6">
                    <div class="form-group">
                        <button type="submit" class="btn lufz-btn w-100"
                            [disabled]="!registerForm.valid">Register</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>