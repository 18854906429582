<div class="page-title-area title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>Privacy Policy</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Privacy Policy</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="privacy-area pt-100">
    <div class="container">
        <div class="privacy-item">
            <h2>1. Overview of Our Privacy Policy</h2>
            <p>At <strong>The Lawyerss</strong>, we are committed to safeguarding your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect your data when you interact with our services or visit our website. Our goal is to maintain transparency and trust while adhering to legal standards and regulations.</p>
        </div>
        
        <div class="privacy-item">
            <h2>2. Applicability of This Privacy Policy</h2>
            <p>This Privacy Policy applies to all clients, users, and visitors who engage with <strong>The Lawyerss</strong> through our website, mobile applications, or other digital platforms. By using our services or accessing our website, you agree to the terms of this Privacy Policy.</p>
            <p>We may update this policy periodically to reflect changes in legal requirements or our practices. We encourage you to review this page regularly to stay informed about how we are protecting your information.</p>
        </div>

        <div class="privacy-item">
            <h2>3. Personal Data We Collect and Process</h2>
            <p>We collect and process different types of personal data to provide and improve our legal services. This includes:</p>
            <ul>
                <li><i class="icofont-simple-right"></i> Personal Identification Information: Your name, email address, and phone number when you reach out to us for consultations or subscribe to our updates.</li>
                <li><i class="icofont-simple-right"></i> Data Related to Service Use: Personal data obtained through interactions on our website, newsletters, and emails, such as your preferences and the information you provide during consultations.</li>
                <li><i class="icofont-simple-right"></i> Technical Data: Information such as your IP address, device details, and browsing behavior on our website, to help us enhance your experience.</li>
                <li><i class="icofont-simple-right"></i> Communication Data: Whether you open our newsletters or emails, and the sections or services you find most relevant.</li>
            </ul>
        </div>
    </div>
</section>
