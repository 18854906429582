import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoaderService } from 'src/app/service/loader.service';
import { MasterService } from 'src/app/service/master.service';

@Component({
  selector: 'app-lawyer-queries',
  templateUrl: './lawyer-queries.component.html',
  styleUrls: ['./lawyer-queries.component.scss']
})
export class LawyerQueriesComponent implements OnInit{
  consultationForm: FormGroup;
  areasOfLaw: any = [];
  frequentlyAskedQuestions: any = [];
  
  constructor(private fb: FormBuilder, private loaderService: LoaderService, private service: MasterService) {}

  ngOnInit(): void {
    this.consultationForm = this.fb.group({
      questionTitle: [''],
      areaOfLaw: [0],
      question: ['']
    });
    this.loadAutoFillData();
  }

  loadAutoFillData() {
    this.loaderService.show();

    const userMasterId = parseInt(localStorage.getItem("userMasterId"));

    const frequentlyAskedQuestions$ = this.service.get(`cust/query/${userMasterId}?pageNo=1&pageSize=5`).pipe(
      catchError((err) => {
        console.log('Failed to get top 5 frequently asked questions.', err);
        return of({ isSuccess: false, data: [] });
      })
    )

    const areasOfLaw$ = this.service.get('master/area_of_law').pipe(
      catchError((err) => {
        console.log('Failed to load practice areas', err);
        return of({ data: [] }); 
      })
    )

    forkJoin({frequentlyAskedQuestions: frequentlyAskedQuestions$, areasOfLaw: areasOfLaw$}).subscribe({
      next: (results: any) => {
        if (results.frequentlyAskedQuestions.isSuccess) {
          this.frequentlyAskedQuestions = results.frequentlyAskedQuestions.data;
        } else {
          console.log("No questions found.");
        }
  
        this.areasOfLaw = results.areasOfLaw.data;
      },
      complete: () => {
        this.loaderService.hide();
      }
    })
  }
  onSubmit(): void {
    if (this.consultationForm.valid) {
      const formData = this.consultationForm.value;
      const payload = {
        "customerUserId": parseInt(localStorage.getItem('userMasterId')),
        "title": formData.questionTitle,
        "areaOfLawId": parseInt(formData.areaOfLaw),
        "question": formData.question
      }
      this.loaderService.show();
      this.service.post('cust/query', payload).subscribe({
        next: (res: any) => {
          this.consultationForm.reset();
        }, error: (err: any) => {
          this.loaderService.hide();
          alert(err.error.Error.Errors[0])
        }, complete: () => {
          this.loaderService.hide();
          alert('Query submitted to lawyer.')
        }
      })
    }
  }
}
