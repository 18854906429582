<div class="page-title-area title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>Attorney Details</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Attorney Details</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="attorney-details pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="attor-details-item">
                    <img src="assets/img/attorney-details/1.jpg" alt="Details">

                    <div class="attor-details-left">
                        <div class="attor-social">
                            <ul>
                                <li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
                                <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
                                <li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                            </ul>
                        </div>

                        <div class="attor-social-details">
                            <h3>Contact info</h3>

                            <ul>
                                <li><i class="flaticon-call"></i> <a href="tel:+07554332322">Call : +91 9823 456 789</a></li>
                                <li><i class="flaticon-email"></i> <a href="mailto:contact@thelawyerss.com">contact@thelawyerss.com</a></li>
                                <li><i class="flaticon-pin"></i> 4th Floor, Chamber No. 408, High Court Building, Dr. Kane Road, Fort, Mumbai, Maharashtra 400032, India</li>
                            </ul>
                        </div>

                        <div class="attor-work">
                            <h3>Working hours</h3>

                            <div class="attor-work-left">
                                <ul>
                                    <li>Monday</li>
                                    <li>Tuesday</li>
                                    <li>Sunday</li>
                                </ul>
                            </div>

                            <div class="attor-work-right">
                                <ul>
                                    <li>9:00 am - 8:00 pm </li>
                                    <li>9:00 am - 8:00 pm </li>
                                    <li>9:00 am - 8:00 pm </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="attor-prev">
                        <ul>
                            <!-- <li><a routerLink="/">Previous</a></li> -->
                            <li><a routerLink="/calendar/9">Check Schedule</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="attor-details-item">
                    <div class="attor-details-right">
                        <div class="attor-details-name">
                            <h2>Adv. Siddharth Rao</h2>
                            <span>Public Prosecutor</span>
                            <p>Bachelor of Laws in LL.B. (Hons) in India</p>
                        </div>

                        <div class="attor-details-things">
                            <h3>Biography</h3>
                            <p>Adv. Siddharth Rao is a seasoned Public Prosecutor with extensive experience in handling high-profile criminal cases. After obtaining her Bachelor of Laws degree (LL.B. Hons) from a prestigious law school in the United Kingdom, Adv. Taylor has consistently demonstrated a commitment to justice and public service. Her expertise in criminal law, combined with a deep understanding of family law, has enabled her to represent the public in complex and sensitive legal matters.</p>
                            <p>As a key member of The Lawyerss legal team, Adv. Siddharth Rao is known for her strategic legal approach, keen analytical skills, and a strong sense of integrity. She is passionate about ensuring that every case she handles is thoroughly prepared, meticulously researched, and effectively presented in court.</p>
                        </div>

                        <div class="attor-details-things">
                            <h3>Education</h3>
                            <ul>
                                <li>Ph.D. in Criminal Law at University of Cambridge (2006)</li>
                                <li>Master of Family Law at London School of Economics and Political Science (2002)</li>
                                <li>Bachelor of Laws (LL.B. Hons) at University of Cambridge (1998)</li>
                                <li>Higher Secondary Certificate at St. Xavier’s College, Mumbai (1991)</li>
                            </ul>
                        </div>
                        
                        <div class="attor-details-things">
                            <h3>Research and Publications</h3>
                            <p>Adv. Sarah Taylor has extensively researched criminal law reform and published influential papers on the effectiveness of judicial processes in protecting public interest. Her research primarily focuses on evolving criminal justice perspectives and safeguarding the rights of vulnerable populations through effective legal frameworks.</p>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-form contact-form-two pb-100">
    <div class="container">
        <div class="contact-wrap">
            <form id="contactForm" class="">
                <h2>Get Appointment</h2>
                <div class="row">
                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <input type="text" name="name" id="name" class="form-control" placeholder="Your Full Name">
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <input type="email" name="email" id="email" class="form-control" placeholder="Your Email">
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Your Phone">
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Subject">
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-12">
                        <div class="form-group">
                            <textarea name="message" class="form-control" id="message" cols="30" rows="8" placeholder="Case Description"></textarea>
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-12">
                        <div class="text-center">
                            <button type="submit" class="contact-btn">Submit Case</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<section class="team-area team-area-two">
    <div class="container">
        <div class="section-title text-start">
            <h2>Our More Expert Attorneys</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="team-item wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                    <img src="assets/img/home-one/team/1.jpg" alt="Team">
                    
                    <div class="team-inner">
                        <ul>
                            <li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                        <h3><a routerLink="/attorney-details">Attor. Jerry Hudson</a></h3>
                        <span>Family Consultant</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                    <img src="assets/img/home-one/team/2.jpg" alt="Team">
                    
                    <div class="team-inner">
                        <ul>
                            <li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                        <h3><a routerLink="/attorney-details">Attor. Juho Hudson</a></h3>
                        <span>Criminal Consultant</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                    <img src="assets/img/home-one/team/3.jpg" alt="Team">
                    
                    <div class="team-inner">
                        <ul>
                            <li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                        <h3><a routerLink="/attorney-details">Attor. Sarah Se</a></h3>
                        <span>Divorce Consultant</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                    <img src="assets/img/home-one/team/4.jpg" alt="Team">

                    <div class="team-inner">
                        <ul>
                            <li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                        <h3><a routerLink="/attorney-details">Attor. Aikin Ward</a></h3>
                        <span>Business Consultant</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>