<div class="page-title-area title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>Consult A Lawyer</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Consult A Lawyer</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="container" style="margin-top: 1rem; margin-bottom: 1rem;">
    <div class="row">
        <!-- Filters Section -->
        <div class="col-md-3">
            <div class="form-group">
                <label for="officeCity">Office City</label>
                <select [(ngModel)]="selectedCity" id="officeCity" class="form-control" (change)="onFilterChange()">
                    <option value="">Select City</option>
                    <option *ngFor="let city of cities" [value]="city">{{ city }}</option>
                </select>
            </div>
            <div class="form-group mt-2">
                <label for="areaOfLaw">Area of Law</label>
                <select [(ngModel)]="selectedAreaOfLaw" id="areaOfLaw" class="form-control" (change)="onFilterChange()">
                    <option value="null" disabled>Select Area of Law</option>
                    <option *ngFor="let area of practiceAreas" [value]="area.id">{{ area.value }}</option>
                </select>
            </div>
        </div>

        <!-- Lawyer Cards Section -->
        <div class="col-md-9">
            <!-- No data found -->
            <div *ngIf="lawyers.length === 0" class="text-center">
                <p>No lawyers available. Please adjust your filters.</p>
            </div>
            <!-- Lawyer cards -->
            <div *ngFor="let lawyer of lawyers" class="my-card" style="border-radius: 10px;">
                <div class="row">
                    <div class="col-md-2 position-relative">
                        <img [src]="lawyer.profilePicUrl" alt="profile_img" class="rounded-circle  profile-img">
                        <span class="verified-icon position-absolute">
                            <i class="mdi--verified"></i>
                        </span>
                    </div>
                    
                    <div class="col-md-6">
                        <h5 class="mb-0">{{lawyer.lawyerFullName}}</h5>
                        <p class="text-small mb-0">{{lawyer.serviceCities?.join(", ")}}</p>
                        <div class="rating">
                            <p>{{lawyer?.userRating}}</p>
                            <div class="rating-stars">
                                <ng-container *ngFor="let _ of [].constructor(getStars(lawyer.userRating).fullStars)">
                                    <i class="zondicons--star-full"></i>
                                </ng-container>
                                <ng-container *ngFor="let _ of [].constructor(getStars(lawyer.userRating).halfStars)">
                                    <i class="fa6-solid--star-half"></i>
                                </ng-container>
                                <ng-container *ngFor="let _ of [].constructor(getStars(lawyer.userRating).emptyStars)">
                                    <i class="zondicons--star-empty"></i>
                                </ng-container>
                            </div>
                            <!-- <p>60 Client Ratings</p> -->
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="exp-ans">
                            <div class="exp">
                                <p>Experience</p>
                                <h6>{{lawyer.profExperience}}+ Yrs</h6>
                            </div>
                            <div class="exp">
                                <p>Answers</p>
                                <h6>1714</h6>
                            </div>
                        </div>
                        <div class="langs">
                            <h6 style="font-size: 16px;">Speaks: <span style="font-weight: 400;">{{lawyer.languagesSpoken ? lawyer.languagesSpoken?.join(", ") : 'NA'}}</span></h6>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-8">
                        <!-- <div class="d-flex flex-wrap gap-2 align-items-center h-100">
                            <span *ngFor="let chip of getDisplayedChips(lawyer.practiceAreasTags).chips"
                                class="badge bg-primary">
                                {{ chip }}
                            </span>
                            <span *ngIf="getDisplayedChips(lawyer.practiceAreasTags).remaining > 0"
                                class="badge bg-secondary rounded-pill">
                                +{{ getDisplayedChips(lawyer.practiceAreasTags).remaining }} More
                            </span>
                        </div> -->
                        <div class="d-flex flex-wrap gap-2 align-items-center h-100">
                            <span *ngFor="let chip of getDisplayedChips(lawyer.practiceAreasTags).chips" class="custom-chip">
                                {{ chip }}
                            </span>
                            <span *ngIf="getDisplayedChips(lawyer.practiceAreasTags).remaining > 0" class="custom-chip custom-chip-more">
                                +{{ getDisplayedChips(lawyer.practiceAreasTags).remaining }} More
                            </span>
                        </div>
                        
                    </div>
                    <div class="col-md-4 text-center">                    
                        <a class="btn lufz-btn" [routerLink]="'/lawyers/'+lawyer.slug">View Profile</a>
                    </div>
                </div>
            </div>
            <!-- Lazy Loading Loader -->
            <div class="loading-progress-bar" *ngIf="isLoading">
                <div class="progress-bar">
                  <div class="progress-fill"></div>
                </div>
              </div>
        </div>
    </div>
</div>