<div class="page-title-area title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>Case Studies Details</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Money Laundering</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="case-details-img pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="case-details-item">
                    <img src="assets/img/case-details/1.jpg" alt="Case">
                </div>
            </div>

            <div class="col-lg-7">
                <div class="case-details-item">
                    <h3>The Challenge of Money Laundering in Modern Financial Systems</h3>
                    <p>One of the major challenges in addressing criminal activities is the ability to disguise illegally acquired funds without alerting law enforcement. Money laundering not only damages the financial integrity of systems but also enables further criminal activities.</p>
                    <ul>
                        <li><span>Retribution</span> – Emphasizing that offenders must face appropriate consequences.</li>
                        <li><span>Deterrence</span> – Focusing on discouraging both the individual and potential offenders.</li>
                        <li><span>Incapacitation</span> – Isolating criminals from society to prevent further harm.</li>
                        <li><span>Rehabilitation</span> – Aiming to transform offenders and integrate them positively into society.</li>
                    </ul>
                    <p>To effectively combat money laundering, comprehensive measures, effective regulation, and rigorous enforcement are required. Financial institutions and legal systems must remain vigilant and proactive to counter such activities.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="case-details-tab">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="case-details-tab-item">
                    <h2>Our Approach to Resolving This Case</h2>

                    <ul class="nav nav-pills" id="pills-tab" role="tablist">
                        <li class="nav-item"><a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Challenge</a></li>

                        <li class="nav-item"><a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Solution</a></li>

                        <li class="nav-item"><a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Result</a></li>
                    </ul>

                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            <h3>Challenges in Tracking and Controlling Money Laundering</h3>
                            <p>Money laundering poses a substantial challenge due to its complexity and its ability to obscure illicit financial transactions. Criminals use various sophisticated methods to launder funds, making it difficult to identify the origins of such transactions and alert law enforcement.</p>
                        </div>

                        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <h3>Implementing Comprehensive Solutions</h3>
                            <p>We employed a multi-faceted approach, focusing on forensic accounting, collaboration with regulatory authorities, and leveraging digital tools to track and disrupt laundering activities. Our solution emphasized transparency, legal compliance, and strategic coordination with key financial institutions.</p>
                        </div>

                        <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                            <h3>Outcome and Lessons Learned</h3>
                            <p>Through this approach, we were able to trace and recover substantial illicit funds, leading to successful prosecution and setting a precedent for future cases. The case highlights the importance of vigilance, advanced technology, and legal expertise in tackling financial crimes effectively.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="case-details-tab-item">
                    <div class="case-details-tab-img">
                        <img src="assets/img/home-one/10.png" alt="Case">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="portfolio-area portfolio-area-three pt-100">
    <div class="container">
        <div class="section-title">
            <h2>Related Cases</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="portfolio-item wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                    <img src="assets/img/home-one/portfolio/1.jpg" alt="Portfolio">

                    <div class="portfolio-inner">
                        <span>Crime</span>
                        <h3><a routerLink="/case-study-details">Critical Murder Case</a></h3>
                        <p>10 Feb 2023</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="portfolio-item wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                    <img src="assets/img/home-one/portfolio/2.jpg" alt="Portfolio">

                    <div class="portfolio-inner">
                        <span>Rights</span>
                        <h3><a routerLink="/case-study-details">Property Problem</a></h3>
                        <p>11 Feb 2023</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="portfolio-item wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                    <img src="assets/img/home-one/portfolio/3.jpg" alt="Portfolio">

                    <div class="portfolio-inner">
                        <span>Finance</span>
                        <h3><a routerLink="/case-study-details">Money Laundering</a></h3>
                        <p>12 Feb 2023</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="portfolio-item wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                    <img src="assets/img/home-one/portfolio/4.jpg" alt="Portfolio">

                    <div class="portfolio-inner">
                        <span>Cyber</span>
                        <h3><a routerLink="/case-study-details">Cyber Crime Case</a></h3>
                        <p>13 Feb 2023</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="portfolio-item wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                    <img src="assets/img/home-one/portfolio/5.jpg" alt="Portfolio">

                    <div class="portfolio-inner">
                        <span>Hack</span>
                        <h3><a routerLink="/case-study-details">Bank Hack Case</a></h3>
                        <p>14 Feb 2023</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="portfolio-item wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                    <img src="assets/img/home-one/portfolio/6.jpg" alt="Portfolio">

                    <div class="portfolio-inner">
                        <span>Politics</span>
                        <h3><a routerLink="/case-study-details">Political Case</a></h3>
                        <p>15 Feb 2023</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
