<section class="error-area">
    <div class="error-item">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="error-text">
                    <h1>404</h1>
                    <p>Case Dismissed: Page Not Found</p>
                    <span>It appears the page you were searching for has been moved or no longer exists. Just like in a court of law, evidence (or in this case, the correct link) is crucial!</span>
                    <a routerLink="/">Return to Home</a>
                    <div class="error-suggestion">
                        <p>If you believe this is an error or need legal assistance, feel free to <a routerLink="/contact">contact us</a>.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
