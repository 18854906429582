<div class="page-title-area title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>FAQ</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>FAQ</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="faq-area pt-100">
    <div class="container">
        <div class="row faq-wrap">
            <div class="col-lg-12">
                <div class="faq-head">
                    <h2>Criminal Law</h2>
                </div>

                <div class="faq-item">
                    <ul class="accordion">
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <a>What is criminal law?</a>
                            <p>Criminal law deals with offenses committed against society or individuals, ensuring justice and maintaining social order. It outlines punishments for criminal activities and provides guidelines for the prosecution and defense of offenders.</p>
                        </li>
                        
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                            <a>What is the purpose of a search warrant?</a>
                            <p>A search warrant is a legal document issued by a judge or magistrate, granting law enforcement authorities permission to search a specified location for evidence related to a crime. The warrant protects citizens’ rights while enabling the investigation of criminal activities.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <a>How long does a criminal case take?</a>
                            <p>The duration of a criminal case depends on its complexity, the nature of the charges, and the legal procedures involved. While some cases can be resolved quickly, others, especially complex or serious ones, may take several months or even years to reach a conclusion.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                            <a>Should I waive time?</a>
                            <p>Waiving time means agreeing to extend the time limits set by law for certain legal procedures. It can be beneficial in some cases to allow your defense attorney more time to prepare, but it’s essential to consult with your lawyer before making this decision.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>Can a defendant be convicted based only on a confession?</a>
                            <p>A confession alone may not be enough to secure a conviction unless supported by additional evidence. Courts often require corroborating evidence to confirm the validity of a confession, ensuring that the accused’s rights are protected and justice is served.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="row faq-wrap">
            <div class="col-lg-12">
                <div class="faq-head">
                    <h2>Cyber Law</h2>
                </div>

                <div class="faq-item">
                    <ul class="accordion">
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <a>Why is there a need for Cyberlaw?</a>
                            <p>Cyberlaw is essential to regulate activities in the digital realm, addressing issues like online fraud, data breaches, privacy violations, and cybercrimes. It establishes legal frameworks to protect individuals, businesses, and governments from digital threats.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                            <a>What is the importance of Cyberlaw?</a>
                            <p>Cyberlaw plays a crucial role in safeguarding digital interactions by ensuring secure online transactions, protecting personal data, and combating cybercrimes. It helps maintain trust in the digital economy and provides legal recourse for victims of cyber offenses.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <a>Does Cyberlaw concern me?</a>
                            <p>Yes, Cyberlaw concerns anyone using digital platforms, including individuals, organizations, and businesses. It covers everything from data privacy to intellectual property rights and cybersecurity, making it relevant for all digital users.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                            <a>What is the general awareness about Cyberlaw today?</a>
                            <p>While awareness about Cyberlaw is growing, many individuals still lack a clear understanding of their rights and responsibilities online. Increasing awareness is vital to prevent cybercrimes and encourage safe and lawful digital interactions.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>What is the stage of development of Cyberlaw today?</a>
                            <p>Cyberlaw is constantly evolving to keep pace with technological advancements. Governments and regulatory bodies worldwide are working to update existing laws and introduce new regulations to tackle emerging digital threats effectively.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="row faq-wrap">
            <div class="col-lg-12">
                <div class="faq-head">
                    <h2>Child Law</h2>
                </div>

                <div class="faq-item">
                    <ul class="accordion">
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <a>What exactly is child custody law?</a>
                            <p>Child custody law determines the legal relationship between a parent and their child, outlining the rights and responsibilities of each parent. It involves decisions about the child’s care, upbringing, and living arrangements.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                            <a>How is child custody typically determined?</a>
                            <p>Child custody is typically determined based on the best interests of the child. Factors such as the child’s age, health, emotional ties with each parent, and the parents’ ability to provide care are considered when making custody decisions.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <a>Is visitation always given to a non-custodial parent?</a>
                            <p>Visitation rights are usually granted to the non-custodial parent unless there are concerns about the child’s safety or well-being. Courts aim to ensure that the child maintains a meaningful relationship with both parents.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                            <a>Should an attorney be hired for a child custody case?</a>
                            <p>Hiring an attorney for a child custody case is advisable to navigate the legal complexities and present a strong case. An experienced lawyer can help protect your rights and advocate for your child’s best interests.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>Will the judge take the wishes of the children into account to determine custody?</a>
                            <p>The judge may consider the wishes of the child, particularly if the child is mature enough to express a preference. However, the final decision will be based on the child’s best interests and overall well-being.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
