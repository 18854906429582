<div class="page-title-area title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>Testimonial</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Testimonial</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="testimonial-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="testimonial-item">
                    <div class="testimonial-wrap">
                        <p>"The Lawyerss team truly exceeded my expectations. They handled my case with exceptional expertise, offering clear guidance and unwavering support throughout the process. I am immensely grateful for their commitment to achieving a successful outcome."</p>
                        <img src="assets/img/testimonial/1.jpg" alt="Testimonial">

                        <div class="testimonial-right">
                            <h3>Rajesh Gupta</h3>
                            <span>Criminal Attorney</span>
                        </div>
                    </div>

                    <div class="testimonial-wrap">
                        <p>"When I needed legal advice for a sensitive family matter, The Lawyerss provided professional support and empathetic counsel. Their attention to detail and dedication to my case was remarkable."</p>
                        <img src="assets/img/testimonial/2.jpg" alt="Testimonial">

                        <div class="testimonial-right">
                            <h3>Anjali Verma</h3>
                            <span>Family Law Specialist</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="testimonial-item">
                    <div class="testimonial-wrap">
                        <p>"I approached The Lawyerss for guidance on a property rights issue, and their team provided clear, actionable legal advice. They navigated my case with great efficiency and professionalism."</p>
                        <img src="assets/img/testimonial/3.jpg" alt="Testimonial">

                        <div class="testimonial-right">
                            <h3>Amit Rathore</h3>
                            <span>Rights Attorney</span>
                        </div>
                    </div>

                    <div class="testimonial-wrap">
                        <p>"The team at The Lawyerss helped me resolve a complex cyber law case involving data breaches. Their expertise and strategic approach ensured that my interests were fully protected."</p>
                        <img src="assets/img/testimonial/4.jpg" alt="Testimonial">

                        <div class="testimonial-right">
                            <h3>Pooja Sharma</h3>
                            <span>Cyber Law Specialist</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="testimonial-item">
                    <div class="testimonial-wrap">
                        <p>"I am grateful to The Lawyerss for their dedication and proactive approach in handling my political dispute case. Their legal team demonstrated exceptional knowledge and commitment, making the entire process seamless."</p>
                        <img src="assets/img/testimonial/5.jpg" alt="Testimonial">
                        
                        <div class="testimonial-right">
                            <h3>Sanjay Patil</h3>
                            <span>Political Attorney</span>
                        </div>
                    </div>

                    <div class="testimonial-wrap">
                        <p>"Working with The Lawyerss was a great experience. They handled my social justice case with compassion and an unwavering focus on achieving a fair resolution. I highly recommend them to anyone seeking legal assistance."</p>
                        <img src="assets/img/testimonial/6.jpg" alt="Testimonial">

                        <div class="testimonial-right">
                            <h3>Kavita Rao</h3>
                            <span>Social Justice Advocate</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="testimonial-more">
            <a class="cmn-btn" routerLink="/testimonial">See More</a>
        </div>
    </div>
</div>
