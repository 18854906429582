import { Component, HostListener, OnInit } from '@angular/core';
import { GenericService } from 'src/app/common/generic.service';
import { LoaderService } from 'src/app/service/loader.service';
import { MasterService } from 'src/app/service/master.service';

@Component({
  selector: 'app-consult-lawyer',
  templateUrl: './consult-lawyer.component.html',
  styleUrls: ['./consult-lawyer.component.scss']
})
export class ConsultLawyerComponent implements OnInit {
  cities: string[] = ['Mumbai', 'Delhi', 'Bangalore', 'Chennai'];
  practiceAreas: { id: number, value: string }[] = [];
  lawyers: any[] = [];
  selectedCity: string = '';
  selectedAreaOfLaw: number | null = null;
  maxVisibleChips: number = 2;
  pageNo: number = 1;
  pageSize: number = 10;
  isLoading: boolean = false;
  hasMoreRecords: boolean = true;

  constructor(private loaderService: LoaderService, private service: MasterService, private genericService: GenericService) { }

  ngOnInit(): void {
    this.loaderService.show();
    window.scrollTo(0,0)
    this.loadPracticeAreas();
    this.loadLawyers();
  }

  loadPracticeAreas() {
    this.service.get('master/area_of_law').subscribe({
      next: (res: any) => {
        this.practiceAreas = res.data;
      },
      error: (err: any) => {
        console.log('Failed to load practice areas', err);
      }
    });
  }

  loadLawyers() {
    if (this.isLoading || !this.hasMoreRecords) {
      return;
    }

    this.isLoading = true;

    this.service.getLawyersForConsult(this.selectedCity, this.selectedAreaOfLaw, this.pageNo, this.pageSize).subscribe({
      next: (res: any) => {
        // Add a Slug to each lawyer object using the normalizeParamForSEO function
        const updatedLawyers = res.data.map((lawyer: any) => ({
          ...lawyer,
          slug: this.genericService.normalizeParamForSEO(lawyer.lawyerFullName, lawyer.lawyerUserId)
        }));

        this.lawyers = [...this.lawyers, ...updatedLawyers];
        this.hasMoreRecords = res.data.length === this.pageSize;
        this.pageNo++;
      },
      error: (err: any) => {
        this.hasMoreRecords = false;
        console.error('Failed to load lawyers', err);
      },
      complete: () => {
        this.isLoading = false;
        this.loaderService.hide();
      }
    });
  }

  onFilterChange() {
    // Resetting the pagination and the data
    this.pageNo = 1;
    this.lawyers = [];
    this.hasMoreRecords = true;
    this.loadLawyers();
  }

  getDisplayedChips(visitingCourts: string[]): { chips: string[], remaining: number } {
    const displayedChips = visitingCourts?.slice(0, this.maxVisibleChips);
    const remainingChipsCount = visitingCourts?.length - this.maxVisibleChips;
    return {
      chips: displayedChips,
      remaining: remainingChipsCount > 0 ? remainingChipsCount : 0
    };
  }

  maxStars: number = 5;
  getStars(rating: number): { fullStars: number; halfStars: number; emptyStars: number } {
    const fullStars = Math.floor(rating);
    const halfStars = rating % 1 >= 0.5 ? 1 : 0;
    const emptyStars = this.maxStars - fullStars - halfStars;

    return {
      fullStars,
      halfStars,
      emptyStars
    };
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const threshold = 200; // Distance from the bottom to trigger load
    const position = window.innerHeight + window.scrollY;
    const height = document.documentElement.scrollHeight;

    if (position > height - threshold && this.hasMoreRecords) {
      this.loadLawyers();
    }
  }
}
