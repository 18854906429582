import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GenericService } from 'src/app/common/generic.service';
import { LoaderService } from 'src/app/service/loader.service';
import { MasterService } from 'src/app/service/master.service';

@Component({
  selector: 'app-lawyer-details',
  templateUrl: './lawyer-details.component.html',
  styleUrls: ['./lawyer-details.component.scss']
})
export class LawyerDetailsComponent implements OnInit {
  lawyerName: string = '';
  lawyerId: number | string = '';
  userRating: any = 4.5;
  lawyerDetails: any = {};

  constructor(
    private route: ActivatedRoute,
    private genericService: GenericService,
    private masterService: MasterService,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    // Retrieve the 'slug' parameter from the URL
    this.route.paramMap.subscribe(params => {
      const slug = params.get('slug');

      if (slug) {
        // Use the GenericService to extract the name and id
        const { name, id } = this.genericService.reverseParamFromSEO(slug);
        this.lawyerName = name;
        this.lawyerId = id;
      }
    });

    this.getLawyerDetails();

  }
  getLawyerDetails() {
    this.loaderService.show();
    console.log('loader shown');
    
    this.masterService.get('lawyer/' + this.lawyerId + '/profile').subscribe({
      next: (res: any) => {
        if(res && res.isSuccess == true){
          this.lawyerDetails = res.data;
        }
        console.log(this.lawyerDetails);
        
      }, error: (err: any) => {
        console.log(err);
        this.loaderService.hide();
      }, complete: () => {
        this.loaderService.hide();
      }
    })
  }
  maxStars: number = 5;
  getStars(rating: number): { fullStars: number; halfStars: number; emptyStars: number } {
    const fullStars = Math.floor(rating);
    const halfStars = rating % 1 >= 0.5 ? 1 : 0;
    const emptyStars = this.maxStars - fullStars - halfStars;

    return {
      fullStars,
      halfStars,
      emptyStars
    };
  }
}
