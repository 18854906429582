import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/service/loader.service';
import { MasterService } from 'src/app/service/master.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {
  registerForm: FormGroup;
  hidePassword = true;
  hideConfirmPassword = true;
  passwordFieldType = 'password';
  confirmPasswordFieldType = 'password';
  invalidForm: boolean = false;
  invalidFormError: string = '';

  constructor(private fb: FormBuilder, private service: MasterService, private loaderService: LoaderService, private router: Router) { }

  ngOnInit(): void {
    // Define the reactive form with validation rules
    this.registerForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: [''],
      mobileNo: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]], // Assumes a 10-digit mobile number
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8), this.passwordValidator()]],
      confirmPassword: ['', Validators.required],
    }, { validators: this.matchPasswords });

    // Reset invalid form error when any value changes
    this.registerForm.valueChanges.subscribe(() => {
      this.invalidForm = false;
      this.invalidFormError = '';
    });
  }

  // Password validator to ensure strong password requirements
  passwordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) return null;

      const hasNumber = /\d/.test(value);
      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasSpecialCharacter = /[!?\*@s%]/.test(value);

      const errors: ValidationErrors = {};

      if (!hasNumber) {
        errors['noNumber'] = 'Your password must contain at least one numeric digit.';
      }
      if (!hasUpperCase) {
        errors['noUpperCase'] = 'Your password must contain at least one uppercase letter.';
      }
      if (!hasLowerCase) {
        errors['noLowerCase'] = 'Your password must contain at least one lowercase letter.';
      }
      if (!hasSpecialCharacter) {
        errors['noSpecialCharacter'] = 'Your password must contain at least one special character (!?*@s%).';
      }

      return Object.keys(errors).length ? errors : null;
    };
  }

  // Validator to ensure password and confirm password fields match
  matchPasswords(control: AbstractControl): ValidationErrors | null {
    const password = control.get('password')?.value;
    const confirmPassword = control.get('confirmPassword')?.value;

    return password === confirmPassword ? null : { passwordsMismatch: 'Passwords do not match' };
  }

  // Toggle password visibility
  togglePasswordVisibility(): void {
    this.hidePassword = !this.hidePassword;
    this.passwordFieldType = this.hidePassword ? 'password' : 'text';
  }

  // Toggle confirm password visibility
  toggleConfirmPasswordVisibility(): void {
    this.hideConfirmPassword = !this.hideConfirmPassword;
    this.confirmPasswordFieldType = this.hideConfirmPassword ? 'password' : 'text';
  }

  // Handle form submission for customer registration
  onSubmit(): void {
    if (this.registerForm.valid) {
      const formData = this.registerForm.value;
      this.loaderService.show();

      // Customer Registration
      this.service.post('customer', {
        "firstName": formData.firstName,
        "lastName": formData.lastName,
        "mobileNo": formData.mobileNo,
        "email": formData.email,
        "password": formData.password,
        "confirmPassword": formData.confirmPassword,
      }).subscribe({
        next: (response: any) => {           
          if (response && response.isSuccess === true) {
            localStorage.setItem('userMasterId', response.data.userMasterId);
            localStorage.setItem('firstName', response.data.firstName);
            localStorage.setItem('lastName', response.data.lastName);
            localStorage.setItem('username', response.data.username);
            localStorage.setItem('email', response.data.email);
            localStorage.setItem('mobileNo', response.data.mobileNo);
            localStorage.setItem('accessToken', response.data.accessToken);
            localStorage.setItem('refreshToken', response.data.refreshToken);
            localStorage.setItem('role', 'Customer');
          }
        }, 
        error: (err) => {
          this.loaderService.hide();
          console.log(err);
          this.invalidForm = true;
          this.invalidFormError = err.error.error.errors[0];
        }, 
        complete: () => {
          this.loaderService.hide();
          window.location.href = '/'; // Redirect to customer dashboard
        }
      });
    }
  }
}
