import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  auth: boolean = false;
  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.router.events.subscribe(() => {
      // Check if the current route is `/login`
      if (this.router.url.startsWith('/login') || this.router.url.startsWith('/calendar/') || this.router.url.startsWith('/lawyer/') || this.router.url.startsWith('/customer/') || this.router.url.startsWith('/register') || this.router.url.startsWith('/lawyers') || this.router.url.startsWith('/forgot-password') || this.router.url.startsWith('/services')) {
        this.auth = true;
      } else {
        this.auth = false;
      }
    });
  }

}
