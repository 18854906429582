<div class="page-title-area title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>Our Services</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Services</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid help-area-two">
    <div class="container py-5">
        <!-- Ask A Lawyer Section -->
        <div class="row align-items-center help-wrap">
            <div class="col-lg-6 order-last order-md-first">
                <div class="help-item">
                    <div class="help-right">
                        <h2>Ask A Lawyer</h2>
                        <p>
                            This is the right site for you if you have specific law questions or legal inquiries. An
                            experienced lawyer will advise you on the legal matters of your interest and ensure that you
                            get the necessary assistance you are looking for.
                        </p>
                        <h4>How It Works</h4>
                        <ul class="list-unstyled text-dark h-18 mb-2">
                            <li><span style="font-weight: 600; display: inline;">Undertake Your Query: </span> <span
                                    style="display: inline;">Provide us with a few details about your legal inquiry by
                                    completing our quick form.</span></li>
                            <li><span style="font-weight: 600; display: inline;">Select Your Lawyer: </span> <span
                                    style="display: inline;">Our matching system will find an appropriate lawyer for
                                    your area of interest.</span></li>
                            <li><span style="font-weight: 600; display: inline;">Get Your Answer: </span> <span
                                    style="display: inline;">Expect a tailored response from your selected lawyer within
                                    24 hours.</span></li>
                        </ul>
                        <h4>Why Choose Us?</h4>
                        <ul class="list-unstyled text-dark h-18 mb-2">
                            <li><span style="font-weight: 600; display: inline;">Expertise You Can Trust:</span> <span
                                    style="display: inline;">Our lawyers are highly educated and experienced.</span>
                            </li>
                            <li><span style="font-weight: 600; display: inline;">Confidential and Secure: </span> <span
                                    style="display: inline;">Your personal details and inquiries are handled with utmost
                                    confidentiality.</span></li>
                        </ul>
                        <!-- <a class="btn lufz-btn mt-3" routerLink="/ask-lawyer">Ask A Lawyer</a> -->
                        <a class="btn lufz-btn mt-3" routerLink="/login">Ask A Lawyer</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 text-center mt-4 mt-lg-0 order-first order-md-last">
                <div class="help-item help-left">
                    <img src="assets/img/service1.webp" class="img-fluid rounded" alt="Ask A Lawyer">
                </div>
            </div>
        </div>

        <!-- Consult A Lawyer Section -->
        <div class="row align-items-center help-wrap flex-lg-row-reverse mt-5">
            <div class="col-lg-6 order-last order-md-first">
                <div class="help-item">
                    <div class="help-right">
                        <h2>Consult A Lawyer</h2>
                        <p>
                            Legal matters are always complicated and stressful – we'd like to make it easier for you!
                            Book a consultation with our skilled legal professionals today.
                        </p>
                        <h4>How It Works</h4>
                        <ul class="list-unstyled text-dark h-18 mb-2">
                            <li><span style="font-weight: 600; display: inline;">Pick a Suitable Date: </span> <span
                                    style="display: inline;">Schedule a date and time for your consultation.</span></li>
                            <li><span style="font-weight: 600; display: inline;">Clearly Define Your Issue: </span>
                                <span style="display: inline;">Provide details about your legal concern to receive
                                    accurate advice.</span></li>
                            <li><span style="font-weight: 600; display: inline;">Get a Professional Perspective: </span>
                                <span style="display: inline;">Receive a detailed response outlining the next
                                    steps.</span></li>
                        </ul>
                        <h4>FAQs</h4>
                        <ul class="list-unstyled text-dark h-18 mb-2">
                            <li><span style="font-weight: 600; display: inline;">How do I schedule a consultation?
                                </span> <span style="display: inline;">Book online or contact us <br /> at
                                    +91-8750956310.</span></li>
                            <li><span style="font-weight: 600; display: inline;">What happens during the consultation?
                                </span> <span style="display: inline;">The lawyer will offer advice and explain possible
                                    next steps.</span></li>
                            <li><span style="font-weight: 600; display: inline;">Do you offer remote consultations?
                                </span> <span style="display: inline;">Yes! Virtual consultations are available.</span>
                            </li>
                        </ul>
                        <!-- <a class="btn lufz-btn mt-3" routerLink="/consult-lawyer">Consult A Lawyer</a> -->
                        <a class="btn lufz-btn mt-3" routerLink="/login">Consult A Lawyer</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 text-center mt-4 mt-lg-0 order-first order-md-last">
                <div class="help-item help-left">
                    <img src="assets/img/service2.webp" class="img-fluid rounded" alt="Consult A Lawyer">
                </div>
            </div>
        </div>

        <!-- Refer A Lawyer Section -->
        <div class="row align-items-center help-wrap mt-5">
            <div class="col-lg-6 order-last order-md-first">
                <div class="help-item">
                    <div class="help-right">
                        <h2>Refer A Lawyer</h2>
                        <p>
                            Finding legal representation is never easy. With ‘Refer A Lawyer’, your troubles will be put
                            to rest as you connect with top legal experts from various fields.
                        </p>
                        <h4>Working Mechanism</h4>
                        <ul class="list-unstyled text-dark h-18 mb-2">
                            <li><span style="font-weight: 600; display: inline;">Fill Out The Form: </span> <span
                                    style="display: inline;">Provide details about your case and required legal
                                    field.</span></li>
                            <li><span style="font-weight: 600; display: inline;">Get Matched: </span> <span
                                    style="display: inline;">We connect you with suitable lawyers based on your
                                    needs.</span></li>
                            <li><span style="font-weight: 600; display: inline;">Consultation: </span> <span
                                    style="display: inline;">Receive contact details of lawyers for direct
                                    discussions.</span></li>
                        </ul>
                        <h4>Why Us?</h4>
                        <ul class="list-unstyled text-dark h-18 mb-2">
                            <li><span style="font-weight: 600; display: inline;">Expertise: </span> <span
                                    style="display: inline;">Our extensive network includes highly experienced
                                    lawyers.</span></li>
                            <li><span style="font-weight: 600; display: inline;">Confidentiality: </span> <span
                                    style="display: inline;">Your information remains private and secure.</span></li>
                            <li><span style="font-weight: 600; display: inline;">Personalized Support: </span> <span
                                    style="display: inline;">We match you with the best lawyer for your
                                    situation.</span></li>
                        </ul>
                        <!-- <button class="btn lufz-btn mt-3" routerLink="/refer-lawyer">Refer A Lawyer</button> -->
                        <button class="btn lufz-btn mt-3" routerLink="/login">Refer A Lawyer</button>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 text-center mt-4 mt-lg-0 order-first order-md-last">
                <div class="help-item help-left">
                    <img src="assets/img/service3.webp" class="img-fluid rounded" alt="Refer A Lawyer">
                </div>
            </div>
        </div>
    </div>
</div>

<style>
    .h-18 {
        line-height: 1.8;
    }
</style>