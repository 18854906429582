<footer>
    <div class="newsletter-area" *ngIf="!auth">
        <div class="container">
            <img src="assets/img/home-one/newsletter.png" alt="Newsletter">
            <h2>Subscribe to Our Legal Insights</h2>

            <form class="newsletter-form">
                <input type="email" class="form-control" placeholder="Enter Your Email" name="EMAIL" required autocomplete="off">
                <button class="btn contact-btn" type="submit">Subscribe</button>
            </form>
        </div>
    </div>

    <div class="container">
        <div class="row" style="padding-top: 2rem;">
            <div class="col-sm-6 col-lg-4">
                <div class="footer-item">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a>
                        <p>At <strong>The Lawyerss</strong>, we are committed to delivering exceptional legal services and support. Connect with us on social media to stay updated on legal news and expert advice from our team.</p>
                        <ul>
                            <li><a href="https://www.youtube.com/" target="_blank"><i class="icofont-youtube-play"></i></a></li>
                            <li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-service">
                        <h3>Our Services</h3>

                        <ul>
                            <li><a routerLink="/practice-details"><i class="icofont-simple-right"></i> Ask a Lawyer</a></li>
                            <li><a routerLink="/practice-details"><i class="icofont-simple-right"></i> Consult a Lawyer</a></li>
                            <li><a routerLink="/practice-details"><i class="icofont-simple-right"></i> Refer a Lawyer</a></li>
                            <!-- <li><a routerLink="/practice-details"><i class="icofont-simple-right"></i> Criminal Law</a></li>
                            <li><a routerLink="/practice-details"><i class="icofont-simple-right"></i> Business Law</a></li> -->
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-2">
                <div class="footer-item">
                    <div class="footer-service">
                        <h3>Quick Links</h3>

                        <ul>
                            <li><a routerLink="/"><i class="icofont-simple-right"></i> Home</a></li>
                            <li><a routerLink="about"><i class="icofont-simple-right"></i> About Us</a></li>
                            <!-- <li><a routerLink="blog"><i class="icofont-simple-right"></i> Blog</a></li> -->
                            <li><a routerLink="attorney"><i class="icofont-simple-right"></i> Team</a></li>
                            <!-- <li><a routerLink="testimonial"><i class="icofont-simple-right"></i> Testimonials</a></li> -->
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-find">
                        <h3>Contact Us</h3>

                        <ul>
                            <li><i class="icofont-location-pin"></i>Plot no 647, Sector-2, , Niladri Vihar, Bhubaneswar, Odisha, 751021, India</li>
                            <li><i class="icofont-ui-call"></i><a href="tel:+918750956310"> +91 8750 956 310</a></li>
                            <li><i class="icofont-at"></i> <a href="mailto:contact@thelawyerss.com"> contact@thelawyerss.com</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="row">
                <div class="col-sm-7 col-lg-6">
                    <div class="copyright-item">
                        <p>© The Lawyerss - All Rights Reserved</p>
                    </div>
                </div>

                <div class="col-sm-5 col-lg-6">
                    <div class="copyright-item copyright-right">
                        <a routerLink="/terms-conditions" target="_blank">Terms & Conditions</a> <span>-</span>
                        <a routerLink="/privacy-policy" target="_blank">Privacy Policy</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<style>
.padding-top-default {
    padding-top: 2rem !important;
}
</style>