<div class="page-title-area title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>Free Legal Advice</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Ask Free Legal Query</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="container" style="margin-top: 1rem; margin-bottom: 1rem;">
    <div class="row">
        <div class="col-md-7">
            <div style="box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;">
                <div class=""
                    style="padding: 16px 24px; background-color: white;border-bottom: 1px solid #e4e4e4; padding: 16px 24px;">
                    <div class="d-flex align-items-center gap-3">
                        <i class="simple-line-icons--envolope"></i>
                        <span>Submit Your Query Now</span>
                    </div>
                </div>
                <div class="" style="padding: 16px 24px;">
                    <form [formGroup]="consultationForm" (ngSubmit)="onSubmit()">
                        <div class="mb-3">
                            <label for="questionTitle" class="form-label">Question Title</label>
                            <input type="text" id="questionTitle" class="form-control"
                                placeholder="Enter Your Question Title" formControlName="questionTitle">
                            <!-- <div *ngIf="consultationForm.get('questionTitle').invalid && consultationForm.get('questionTitle').touched" class="text-danger mt-0" style="font-size: 14px;">
                          <small style="font-size: 14px !important;">Question title is required.</small>
                        </div> -->
                        </div>

                        <div class="mb-3">
                            <label for="areaOfLaw" class="form-label">Area Of Law</label>
                            <select id="areaOfLaw" class="form-select" formControlName="areaOfLaw">
                                <option value="0" disabled>Select Area of Law</option>
                                <option *ngFor="let area of areasOfLaw" [value]="area.id">{{ area.value }}</option>
                            </select>
                            <!-- <app-custom-dropdown 
                            [items]="lawAreas" 
                            (selectedItem)="onAreaOfLawSelect($event)">
                        </app-custom-dropdown> -->
                            <!-- <div *ngIf="consultationForm.get('areaOfLaw').invalid && consultationForm.get('areaOfLaw').touched" class="text-danger mt-0" style="font-size: 14px;">
                          <small style="font-size: 14px !important;">Please select an area of law.</small>
                        </div> -->
                        </div>

                        <div class="mb-3">
                            <label for="question" class="form-label">Question</label>
                            <textarea id="question" class="form-control" rows="5"
                                placeholder="Type your Question here..." formControlName="question">
                        </textarea>
                            <!-- <div *ngIf="consultationForm.get('question').invalid && consultationForm.get('question').touched" class="text-danger mt-0" style="font-size: 14px;">
                          <small style="font-size: 14px !important;">Question is required.</small>
                        </div> -->
                        </div>

                        <div class="d-grid">
                            <button type="submit" class="btn lufz-btn" [disabled]="consultationForm.invalid">
                                Ask Legal Query
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div style="box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;">
                <div class="query-header" style="border-bottom: 1px solid #e4e4e4; padding: 16px 24px;">
                    <div class="d-flex align-items-center gap-3">
                        <i class="typcn--messages"></i>
                        <span>Recent Legal Queries</span>
                    </div>
                </div>
                <div class="queries" style="border-bottom: 1px solid #e4e4e4; padding: 16px 24px;"
                    *ngFor="let faq of frequentlyAskedQuestions">
                    <h5 style="font-weight: 400;">{{faq.title}}</h5>
                    <span
                        style="background: #e0f5d7; color: #449626; font-size: .9rem; padding: 4px 8px;">{{faq.areaOfLaw}}</span>
                    <p style="font-size: 0.9rem; margin-top: 5px;">{{faq.question}}</p>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="row">
        <div class="col-md-6">
            <div id="legal-query-accordion" class="accordion">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            How to Ask a Free Legal Query. Here are the Tips
                        </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne">
                        <div class="accordion-body">
                            <p>Asking a free legal query can be a daunting task, especially if you are unfamiliar with the
                                legal system. However, with the right approach, you can get the best results. Here are some
                                tips to help you ask a free legal query and get the best results:</p>
    
                            <ol>
                                <li>
                                    <strong>Research your query thoroughly</strong>
                                    <p>Before asking a free legal query, it is important to do your research. Make sure you
                                        understand the legal issue you are asking about and have a clear understanding of
                                        the facts of your case. This will help you formulate a more specific and accurate
                                        query.</p>
                                </li>
                                <li>
                                    <strong>Ask a specific question</strong>
                                    <p>When asking a free legal query, make sure to ask a specific question. This will help
                                        you get a more detailed and accurate answer.</p>
                                </li>
                                <li>
                                    <strong>Provide relevant information</strong>
                                    <p>When asking a free legal query, make sure to provide all relevant information. This
                                        includes any documents or evidence that may be relevant to your query.</p>
                                </li>
                                <li>
                                    <strong>Be clear and concise</strong>
                                    <p>When asking a free legal query, make sure to be clear and concise. This will help the
                                        lawyer or legal expert understand your query more easily and provide a more accurate
                                        answer.</p>
                                </li>
                                <li>
                                    <strong>Use Proper Grammar and Punctuation</strong>
                                    <p>When writing a free legal query, it is important to use proper grammar and
                                        punctuation. This will help ensure that the query is professional and easy to
                                        understand.</p>
                                </li>
                                <li>
                                    <strong>Proofread</strong>
                                    <p>Before submitting a free legal query, it is important to proofread it for any errors.
                                        This will help ensure that the query is accurate and free of any mistakes.</p>
                                </li>
                                <li>
                                    <strong>Be patient</strong>
                                    <p>When asking a free legal query, it is important to be patient. Legal queries can take
                                        time to answer, so make sure to give the lawyer or legal expert enough time to
                                        provide a thorough response.</p>
                                </li>
                            </ol>
                            <p>By following these tips, you can ask a free legal query and get the best results.</p>
                        </div>
                    </div>
                </div>
    
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Pros and Cons of Asking a Free Legal Query
                        </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo">
                        <div class="accordion-body">
                            <p>When it comes to legal matters, it is important to understand the pros and cons of asking a
                                free legal query. On the one hand, asking a free legal query can be a great way to get quick
                                answers to legal questions without having to pay for a lawyer. On the other hand, there are
                                some drawbacks to asking a free legal query that should be considered.</p>
                            <br>
                            <p>The primary benefit of asking a free legal query is that it can provide quick answers to
                                legal questions without having to pay for a lawyer. This can be especially helpful for those
                                who are on a tight budget and cannot afford to hire a lawyer. Additionally, free legal
                                queries can provide a great starting point for those who are just beginning to research a
                                legal issue.</p>
                            <br>
                            <p>However, there are some drawbacks to asking a free legal query. First, the answers provided
                                may not be as comprehensive as those provided by a lawyer. Additionally, the answers may not
                                be tailored to the individual’s specific situation. Furthermore, the answers may not be
                                legally binding, meaning that they may not be enforceable in court.</p>
                            <br>
                            <p>In conclusion, it is important to understand the pros and cons of asking a free legal query.
                                While it can be a great way to get quick answers to legal questions without having to pay
                                for a lawyer, it is important to be aware of the potential drawbacks. It is also important
                                to remember that the answers provided may not be as comprehensive or legally binding as
                                those provided by a lawyer.</p>
                        </div>
                    </div>
                </div>
    
    
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            What to Expect When You Ask a Free Legal Query
                        </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree">
                        <div class="accordion-body">
                            <p>When you ask a free legal query, you can expect to receive a response from a qualified legal
                                professional. The response will provide you with an overview of the legal issue you have
                                raised and may include advice on how to proceed.</p>
                            <br>
                            <p>The response will be tailored to your specific query and will be based on the information you
                                provide. It is important to provide as much detail as possible when asking a free legal
                                query, as this will help the legal professional to provide the most accurate advice.</p>
                            <br>
                            <p>The response you receive will be provided in a professional and formal manner. It will be
                                written in plain language and will be easy to understand. The response will not provide a
                                definitive answer to your query, but will provide you with an overview of the legal issue
                                and advice on how to proceed.</p>
                            <br>
                            <p>It is important to remember that the response you receive is not a substitute for
                                professional legal advice. If you require further assistance, <b>you should book a
                                    consultation with a qualified legal professional</b>.</p>
                        </div>
                    </div>
                </div>
                
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingfour">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                            What Kinds of Questions Can You Ask in a Free Legal Query?
                        </button>
                    </h2>
                    <div id="collapsefour" class="accordion-collapse collapse" aria-labelledby="headingfour">
                        <div class="accordion-body">
                            <p>When seeking legal advice, it is important to ask questions that are specific and relevant to
                                your situation. Here are some examples of questions you can ask in a free legal query:</p>
                            <ol>
                                <li>What are my legal rights in this situation?</li>
                                <li>What are the potential legal consequences of my actions?</li>
                                <li>What are the possible outcomes of this case?</li>
                                <li>What are the legal requirements for this type of situation?</li>
                                <li>What are the potential risks associated with this course of action?</li>
                                <li>What are the legal options available to me?</li>
                                <li>What are the time limits for taking legal action?</li>
                                <li>What documents do I need to provide to support my case?</li>
                                <li>What are the costs associated with pursuing this legal action?</li>
                                <li>What are the potential legal remedies available to me?</li>
                            </ol>
                        </div>
                    </div>
                </div>
                
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingfive">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
                            What to Do If You Don’t Get a Satisfactory Answer to Your Free Legal Query
                        </button>
                    </h2>
                    <div id="collapsefive" class="accordion-collapse collapse" aria-labelledby="headingfive">
                        <div class="accordion-body">
                            <p>If you don’t get a satisfactory answer to your free legal query, there are a few steps you
                                can take to get the help you need.</p>
                            <br>
                            <p>First, you should consider consulting a lawyer. A lawyer can provide you with personalized
                                advice and help you understand the legal implications of your situation. You can find a
                                lawyer through the American Bar Association’s website or by asking friends and family for
                                referrals.</p>
                            <br>
                            <p>Second, you can look for free legal resources online. There are many websites that offer free
                                legal advice and information. You can also find legal forms and documents online.</p>
                            <br>
                            <p>Third, you can contact a local legal aid organization. These organizations provide free legal
                                services to those who cannot afford to hire a lawyer. You can find a legal aid organization
                                in your area by searching online or by calling your local bar association.</p>
                            <br>
                            <p>Finally, you can contact your state or local bar association. Many bar associations offer
                                free legal advice and resources. You can also find out about upcoming legal seminars and
                                workshops.</p>
                            <br>
                            <p>No matter what steps you take, it is important to remember that free legal advice is not a
                                substitute for professional legal advice. If you have a legal issue, it is best to consult a
                                lawyer.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="card">
                <div class="card-header">
                    <p class="d-flex align-items-center mb-0">
                        <i class="simple-line-icons--envolope me-2"></i>
                        Submit Your Query Now
                    </p>
                </div>
                <div class="card-body">
                    <form>
                        <div class="mb-3">
                            <label for="questionTitle" class="form-label">Question Title</label>
                            <input type="text" class="form-control" id="questionTitle"
                                placeholder="Enter your question title" required>
                        </div>
                        <div class="mb-5">
                            <label for="questionType" class="form-label">Question Type</label>
                            <select class="form-select" id="questionType" required>
                                <option value="" disabled selected>Select question type</option>
                                <option value="general">General Inquiry</option>
                                <option value="legal">Legal Inquiry</option>
                                <option value="financial">Financial Inquiry</option>
                                
                            </select>
                        </div>
                        <div class="mb-3 mt-2">
                            <label for="questionText" class="form-label">Your Question</label>
                            <textarea class="form-control" id="questionText" rows="3" placeholder="Write your question here"
                                required></textarea>
                        </div>
                        <button type="submit" class="btn btn-dark">Ask Query</button>
                    </form>
                </div>
            </div>
        </div>
    </div> -->
</div>