<div class="page-title-area title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>Practice Details</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Practice Details</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="practice-details-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="practice-details-item">
                    <div class="practice-details-content">
                        <div class="section-title text-start">
                            <h2>Criminal Law</h2>
                        </div>
                        <p>Criminal Law encompasses laws and regulations established to maintain public order and provide justice to victims of crime. Our experienced attorneys offer comprehensive legal assistance to clients facing criminal charges, ensuring that every defense is meticulously prepared and presented. We focus on achieving fair outcomes and upholding the rights of the accused in every case.</p>
                        <p>At *The Lawyerss*, our criminal law practice covers a wide range of offenses, including serious crimes, fraud, cybercrime, and white-collar offenses. We provide strategic counsel and robust representation, backed by a deep understanding of legal frameworks and judicial processes.</p>
                        <ul>
                            <li><span>Retribution –</span> Criminal acts demand proportionate consequences to uphold justice.</li>
                            <li><span>Deterrence –</span> Emphasizing individual and societal deterrence to discourage criminal behavior.</li>
                            <li><span>Incapacitation – </span> Isolating offenders to protect society from future harm.</li>
                            <li><span>Rehabilitation –</span> Focusing on transforming offenders into contributing members of society.</li>
                        </ul>
                        <p>Our approach is built on professionalism, transparency, and dedication to securing the best possible outcomes for our clients. Whether facing trial or negotiating settlements, we stand by our clients at every stage of the legal process.</p>
                    </div>
                </div>

                <div class="practice-details-case">
                    <div class="row">
                        <div class="col-sm-6 col-lg-6">
                            <div class="portfolio-item wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                <img src="assets/img/home-one/portfolio/1.jpg" alt="Portfolio">

                                <div class="portfolio-inner">
                                    <span>Crime</span>
                                    <h3><a routerLink="/case-study-details">Critical Murder Case</a></h3>
                                    <p>15 Feb 2023</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-6">
                            <div class="portfolio-item wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                                <img src="assets/img/home-one/portfolio/2.jpg" alt="Portfolio">

                                <div class="portfolio-inner">
                                    <span>Rights</span>
                                    <h3><a routerLink="/case-study-details">Property Problem</a></h3>
                                    <p>16 Feb 2023</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-6">
                            <div class="portfolio-item wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                <img src="assets/img/home-one/portfolio/3.jpg" alt="Portfolio">

                                <div class="portfolio-inner">
                                    <span>Finance</span>
                                    <h3><a routerLink="/case-study-details">Money Laundering</a></h3>
                                    <p>17 Feb 2023</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-6">
                            <div class="portfolio-item wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                                <img src="assets/img/home-one/portfolio/1.jpg" alt="Portfolio">

                                <div class="portfolio-inner">
                                    <span>Crime</span>
                                    <h3><a routerLink="/case-study-details">Cyber Crime Case</a></h3>
                                    <p>18 Feb 2023</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="practice-details-item">
                    <div class="blog-details-category">
                        <ul>
                            <li><a routerLink="/practice-details">Criminal Law <i class="icofont-arrow-right"></i></a></li>
                            <li><a routerLink="/practice-details">Civil Law <i class="icofont-arrow-right"></i></a></li>
                            <li><a routerLink="/practice-details">Education Law <i class="icofont-arrow-right"></i></a></li>
                            <li><a routerLink="/practice-details">Family Law <i class="icofont-arrow-right"></i></a></li>
                            <li><a routerLink="/practice-details">Cyber Law <i class="icofont-arrow-right"></i></a></li>
                        </ul>
                    </div>

                    <div class="practice-details-contact">
                        <h3>Contact Info</h3>

                        <ul>
                            <li><i class="flaticon-call"></i> <a href="tel:+919823456789">Call : +91 9823 456 789</a></li>
                            <li><i class="flaticon-email"></i> <a href="mailto:contact@thelawyerss.com">contact@thelawyerss.com</a></li>
                            <li><i class="flaticon-pin"></i> 4th Floor, Chamber No. 408, High Court Building, Dr. Kane Road, Fort, Mumbai, Maharashtra 400032, India</li>
                        </ul>
                    </div>

                    <div class="practice-details-brochures">
                        <h3>Brochures</h3>
                        <p>Download our brochures to learn more about our legal services and how we can assist you with your legal needs.</p>
                        <a class="cmn-btn" href="#"><i class="flaticon-download"></i>Download</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="help-shape">
        <img src="assets/img/home-one/6.png" alt="Shape">
    </div>
</div>
