import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-search-dpd',
  templateUrl: './custom-search-dpd.component.html',
  styleUrls: ['./custom-search-dpd.component.scss']
})
export class CustomSearchDpdComponent implements OnInit{
  @Input() items: string[] = [];
  searchText: string = '';
  filteredItems: string[] = [];
  isDropdownOpen: boolean = true;

  constructor() {}

  ngOnInit() {
    this.filteredItems = this.items;
  }

  filterItems() {
    if (this.searchText) {
      const lowerCaseSearchText = this.searchText.toLowerCase();
      this.filteredItems = this.items.filter(item => 
        item.toLowerCase().includes(lowerCaseSearchText)
      );
    } else {
      this.filteredItems = this.items;
    }
  }

  highlightMatch(text: string, search: string) {
    if (!search) {
      return text;
    }
    const regex = new RegExp(`(${search})`, 'gi');
    return text.replace(regex, `<span class="highlight">$1</span>`);
  }

  selectItem(item: string) {
    console.log('Selected Item:', item);
    this.searchText = item;
    this.isDropdownOpen = false;
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
}
