<div class="dropdown">
    <div class="form-group position-relative">
      <input 
        type="text" 
        class="form-control" 
        placeholder="Search..." 
        [(ngModel)]="searchText" 
        (input)="filterItems()">
      <i class="fa fa-search position-absolute top-50 end-0 translate-middle pe-2"></i>
    </div>
    <div class="dropdown-menu show w-100" *ngIf="filteredItems.length > 0 && isDropdownOpen">
      <a 
        *ngFor="let item of filteredItems" 
        class="dropdown-item" 
        (click)="selectItem(item)">
        <span [innerHTML]="highlightMatch(item, searchText)"></span>
      </a>
    </div>
    <div class="dropdown-menu show w-100" *ngIf="filteredItems.length === 0 && isDropdownOpen">
      <a class="dropdown-item text-muted">No results found</a>
    </div>
  </div>
  