import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GenericService {

  constructor() { }

  /**
   * Normalizes a name for SEO-friendly URL usage by converting it to lowercase, 
   * removing special characters (like apostrophes), replacing spaces with hyphens, 
   * and appending the provided ID.
   * 
   * @param name - The name to be normalized (e.g., "Advocate O'Connor").
   * @param id - The identifier to append to the normalized name (e.g., 1889).
   * @returns A string that combines the normalized name and ID, suitable for use in URLs 
   *          (e.g., "advocate-oconnor-1889").
   */
  normalizeParamForSEO(name: string, id: number | string): string {
    // Convert the name to lowercase and replace spaces or non-alphanumeric characters with hyphens
    const normalizedName = name
      .toLowerCase()
      .trim()
      .replace(/['’]+/g, '') // Remove apostrophes
      .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
      .replace(/^-+|-+$/g, ''); // Remove leading and trailing hyphens

    // Return the normalized name combined with the ID
    return `${normalizedName}-${id}`;
  }

  /**
   * Extracts the original name and ID from a given slug.
   * 
   * @param slug - The slug to be parsed.
   * @returns An object containing the extracted name and ID.
   */
  reverseParamFromSEO(slug: string): { name: string, id: number | string } {
    // Extract the ID from the end of the slug
    const slugParts = slug.split('-');
    const id = slugParts.pop() || '';

    // Join the remaining parts back into the original name
    const name = slugParts.join(' ')
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize each word

    return { name, id };
  }
}
